import { Link } from 'react-router-dom'
import React from 'react'

function Banner() {
  const heroSection = {
    backgroundImage: 'url(../img/hero-bg-1.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom center',
    backgroundSize: 'cover',
    width: '100%',
    height: '600px',
    position: 'relative',
    borderTop: '1px solid #eaeaea',
    zIndex: 1,
  }
  return (
    <section
      id="home"
      className="hero-section d-flex align-items-center"
      style={heroSection}
    >
      <div className="anim-elements">
        <div className="anim-element" />
        <div className="anim-element" />
        <div className="anim-element" />
        <div className="anim-element" />
        <div className="anim-element" />
      </div>
      <div className="container pos-rel">
        <div className="row hero-wrap d-flex align-items-center">
          <div className="col-lg-6 col-md-8 sm-padding">
            <div className="hero-content">
              {/* <h4>
                <i className="fa fa-check" />
                #Editors Choice App Of 2020
              </h4> */}
              <h1>
                Choose US As Your Best IT Partner
                <br />
                For Your Impregnable Business Growth!!!!
              </h1>
              <p>
                We've Expertise in WEB DESIGNING ( Start Up Business,
                Responsive, Dynamic)| WEB DEVELOPMENT (E Commerce Portal etc)|
                SEO Services ( BE DIGITALLY VISIBLE)| PPC (Pay Per Click)|
                Social Media Promotion (SMO)
              </p>
              <div className="btn-group">
                <Link className="default-btn" to="/contact-us">
                  Contact Us
                  <span />
                </Link>
                {/* <a
                  href="https://youtu.be/bS5P_LAqiVg"
                  className="play-btn lightbox"
                  data-vbtype="video"
                >
                  <i className="fas fa-play" />
                  How It Works?
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="hero-moc" />
      </div>
    </section>
  )
}

export default React.memo(Banner)
