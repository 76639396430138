import React from 'react'
import { Link } from 'react-router-dom'

import about from '../../assets/img/about.png'

function AboutHome() {
  return (
    <div>
      <section className="content-section pos-rel">
        <div className="left-pattern d-none d-lg-block" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 d-none d-lg-block">
              <img className="content-moc" src={about} alt />
            </div>
            <div className="col-lg-7">
              <div className="content-info">
                <h2>About Web Grow Solutions</h2>
                <p>
                  <strong>
                    In today’s growing era of technology ‘Web Grow Solutions’
                    may be a renowned name within the industry of Development
                    Company across the planet .
                  </strong>
                </p>
                <p>
                  Established in UP, India's Web Grow Solutions is among the
                  best Development companies in India. ‘Web Grow Solutions’ came
                  into existence after its parent company ‘Luhaif Developers’
                  which features a collaborative experience of 11 years
                  established in Kanpur(UP). We are always here to lend you a
                  hand in order that you'll take the advantage of selling
                  prospects across a spread of channels in real-time.
                </p>
                <Link className="default-btn" to="/contact-us">
                  Contact Us
                  <span />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default React.memo(AboutHome)
