import React from 'react'
import graphicDesign from '../../assets/img/graphic-design.png'
import onlineShop from '../../assets/img/online-shop.png'
import developer from '../../assets/img/developer.png'
import development from '../../assets/img/mobile-development.png'
import uxdesign from '../../assets/img/ux-design.png'
import seo from '../../assets/img/seo.png'
import { Link } from 'react-router-dom'
function Features() {
  return (
    <div>
      <section id="feature" className="features-section padding">
        <div className="container">
          <div className="section-heading text-center mb-40">
            <h2>Our Services</h2>
            <p>
              ‘Webgrow Solutions’is especially fabricated to reduce the gap
              between you and the heights of success<br></br> you wish to have
              for your business.
            </p>
          </div>
          <div className="row ">
            <div className="col-md-4 col-sm-6 sm-padding ab">
              <Link to="">
                {' '}
                <div className="features-item">
                  <img src={onlineShop} />
                  <h3 className="color-red">E-Commerce Development</h3>
                  <p className="text-grey">
                    Fully optimised eCommerce experience that sells across all
                    devices so you never lose a sale to compatibility issues
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-sm-6 sm-padding">
              <Link to="">
                {' '}
                <div className="features-item">
                  <img src={graphicDesign} />
                  <h3 className="color-blue">Graphic Designing</h3>
                  <p className="text-grey">
                    Brand your company, match everything you do so you become a
                    recognized brand. We can help!
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-sm-6 sm-padding">
              <Link to="">
                <div className="features-item">
                  <img src={developer} />
                  <h3 className="color-green">Web Development</h3>
                  <p className="text-grey">
                    Web Development is name given to web programming where the
                    technical programmers deal in framing & coding
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4 col-sm-6 sm-padding">
              <Link to="">
                <div className="features-item">
                  <img src={development} />
                  <h3 className="color-black">Android App Development</h3>
                  <p className="text-grey">
                    Design a native app experience that extends your brands
                    experience past the web
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-sm-6 sm-padding">
              <Link to="">
                <div className="features-item">
                  <img src={uxdesign} />
                  <h3 className="color-pinks">Web Design</h3>
                  <p className="text-grey">
                    Responsive websites that work in a variety of screen sizes
                    for today's multiple device driven market.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-sm-6 sm-padding">
              <Link to="">
                <div className="features-item">
                  <img src={seo} />
                  <h3 className="color-purple">Search Engine Optimization</h3>
                  <p className="text-grey">
                    With the high support of professionals, we are providing SEO
                    Solution Service to our customers.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default React.memo(Features)
