import React from 'react'

function Faq() {
  return (
    <div>
      <section id="faq" className="faq-section padding">
        <div className="anim-elements">
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
        </div>
        <div className="container pos-rel">
          <div className="faq-wrap row">
            <div className="col-md-6 offset-md-6">
              <div className="section-heading mb-40">
                <h2>Frequently Asked Question!</h2>
                <p>
                  One touch of a red-hot stove is usually all we need to avoid
                  that kind of discomfort in the future. The same is true as we
                  experience the emotional.
                </p>
              </div>
              <div id="accordion" className="home-3-acc" role="tablist">
                <div className="acc-item active-acc">
                  <h5 id="faq1">
                    <a
                      data-toggle="collapse"
                      href="#collapse1"
                      aria-expanded="true"
                      aria-controls="collapse1"
                    >
                      How To Buy This?
                    </a>
                  </h5>
                  <div
                    id="collapse1"
                    className="collapse show"
                    role="tabpanel"
                    aria-labelledby="faq1"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      We provide marketing services to startups and small
                      businesses to looking for a partner of their digital
                      media, design &amp; development lead generation.
                    </div>
                  </div>
                </div>
                <div className="acc-item">
                  <h5 id="faq2">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      href="#collapse2"
                      aria-expanded="false"
                      aria-controls="collapse2"
                    >
                      Is It Refundable?
                    </a>
                  </h5>
                  <div
                    id="collapse2"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="faq2"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      We provide marketing services to startups and small
                      businesses to looking for a partner of their digital
                      media, design &amp; development lead generation.
                    </div>
                  </div>
                </div>
                <div className="acc-item">
                  <h5 id="faq3">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      href="#collapse3"
                      aria-expanded="false"
                      aria-controls="collapse3"
                    >
                      Can I Use Free?
                    </a>
                  </h5>
                  <div
                    id="collapse3"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="faq3"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      We provide marketing services to startups and small
                      businesses to looking for a partner of their digital
                      media, design &amp; development lead generation.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="faq-shape d-none d-md-block" />
        </div>
      </section>
    </div>
  )
}

export default React.memo(Faq)
