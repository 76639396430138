import React from 'react'
import AboutHome from '../../containers/about/AboutHome'
import Footer from '../../containers/common/footer/Footer'
import Header from '../../containers/common/header/Header'
import PageBanner from '../../containers/common/pagebanner/PageBanner'
import ContactUsToday from '../../containers/contactus/ContactUsToday'
import Features from '../../containers/Feature/Features'
import FunFact from '../../containers/funfact/FunFact'

function About(props) {
  return (
    <div>
      <Header />
      <PageBanner heading={'About Us'} paragraph={'lorem Ipsum'} />

      <section className="content-section padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 xs-padding">
              <div className="content-info">
                <img src="img/content-1.png" alt="img" />
              </div>
            </div>
            <div className="col-md-6 xs-padding">
              <div className="content-info">
                <h2>
                  Design And Build By The <br />
                  Latest Code Integration.
                </h2>
                <p>
                  We provide marketing services to startups and small businesses
                  to looking for a partner of their digital media, design &amp;
                  development, lead generation and communications requirents.
                </p>
                <a className="default-btn" href="#">
                  Get Started
                  <span />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FunFact />
      <Features />
      <ContactUsToday />
      <Footer />
    </div>
  )
}

export default React.memo(About)
