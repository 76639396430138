export const SERVICES = [
  {
    slug: 'wordpress-development',
    service_title: 'Wordpress Development',
    srevice_heading: 'The #1 Tool For Crerating Stunning Marketing',
    service_content: `<p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation.</p>
    <p>It is a long established fact that a reader will be distract ed by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using and content here.</p>
    `,
    service_image: '/img/wordpresss-development.png',
    service_logo: [
      '/img/clients/nizam-uloom.png',
      '/img/clients/avagata.png',
      '/img/clients/accounting.png',
      '/img/clients/geek.png',
      '/img/clients/ezshoprz.png',
      '/img/clients/saree.png',
    ],
  },
  //   software developement
  {
    slug: 'software-development',
    service_title: 'Software Development',
    srevice_heading: 'The #1 Tool For Crerating Stunning Marketing',
    service_content: `<p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation.</p>
    <p>It is a long established fact that a reader will be distract ed by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using and content here.</p>
    `,
    service_image: '/img/software-development.png',
    service_logo: [
      '/img/clients/nizam-uloom.png',
      '/img/clients/avagata.png',
      '/img/clients/accounting.png',
      '/img/clients/geek.png',
      '/img/clients/ezshoprz.png',
      '/img/clients/saree.png',
    ],
  },
  //   app Development
  {
    slug: 'app-development',
    service_title: 'App Development',
    srevice_heading: 'The #1 Tool For Crerating Stunning Marketing',
    service_content: `<p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation.</p>
    <p>It is a long established fact that a reader will be distract ed by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using and content here.</p>
    `,
    service_image: '/img/app-development.jpg',
    service_logo: [
      '/img/clients/nizam-uloom.png',
      '/img/clients/avagata.png',
      '/img/clients/accounting.png',
      '/img/clients/geek.png',
      '/img/clients/ezshoprz.png',
      '/img/clients/saree.png',
    ],
  },
  //   Web Development
  {
    slug: 'web-development',
    service_title: 'Web Development',
    srevice_heading: 'The #1 Tool For Crerating Stunning Marketing',
    service_content: `<p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation.</p>
    <p>It is a long established fact that a reader will be distract ed by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using and content here.</p>
    `,
    service_image: '/img/web-development.png',
    service_logo: [
      '/img/clients/nizam-uloom.png',
      '/img/clients/avagata.png',
      '/img/clients/accounting.png',
      '/img/clients/geek.png',
      '/img/clients/ezshoprz.png',
      '/img/clients/saree.png',
    ],
  },
  //   Php Development
  {
    slug: 'php-development',
    service_title: 'PHP Development',
    srevice_heading: 'The #1 Tool For Crerating Stunning Marketing',
    service_content: `<p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation.</p>
        <p>It is a long established fact that a reader will be distract ed by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using and content here.</p>
        `,
    service_image: '/img/php-development.png',
    service_logo: [
      '/img/clients/nizam-uloom.png',
      '/img/clients/avagata.png',
      '/img/clients/accounting.png',
      '/img/clients/geek.png',
      '/img/clients/ezshoprz.png',
      '/img/clients/saree.png',
    ],
  },
  //   Ecommerce Development
  {
    slug: 'ecommerce-development',
    service_title: 'Ecommerce Development',
    srevice_heading: 'The #1 Tool For Crerating Stunning Marketing',
    service_content: `<p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation.</p>
            <p>It is a long established fact that a reader will be distract ed by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using and content here.</p>
            `,
    service_image: '/img/e-commerce.png',
    service_logo: [
      '/img/clients/nizam-uloom.png',
      '/img/clients/avagata.png',
      '/img/clients/accounting.png',
      '/img/clients/geek.png',
      '/img/clients/ezshoprz.png',
      '/img/clients/saree.png',
    ],
  },
  //   Digital Marketing
  {
    slug: 'digital-marketing',
    service_title: 'Digital Marketing',
    srevice_heading: 'The #1 Tool For Crerating Stunning Marketing',
    service_content: `<p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation.</p>
                <p>It is a long established fact that a reader will be distract ed by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using and content here.</p>
                `,
    service_image: '/img/digital.png',
    service_logo: [
      '/img/clients/nizam-uloom.png',
      '/img/clients/avagata.png',
      '/img/clients/accounting.png',
      '/img/clients/geek.png',
      '/img/clients/ezshoprz.png',
      '/img/clients/saree.png',
    ],
  },
  //   SEO
  {
    slug: 'search-engine-optimization',
    service_title: '  Search Engine Optimization',
    srevice_heading: 'The #1 Tool For Crerating Stunning Marketing',
    service_content: `<p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation.</p>
                    <p>It is a long established fact that a reader will be distract ed by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using and content here.</p>
                    `,
    service_image: '/img/seoo.png',
    service_logo: [
      '/img/clients/nizam-uloom.png',
      '/img/clients/avagata.png',
      '/img/clients/accounting.png',
      '/img/clients/geek.png',
      '/img/clients/ezshoprz.png',
      '/img/clients/saree.png',
    ],
  },
  //   PPC
  {
    slug: 'ppc-advertising',
    service_title: 'PPC Advertising',
    srevice_heading: 'The #1 Tool For Crerating Stunning Marketing',
    service_content: `<p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation.</p>
                        <p>It is a long established fact that a reader will be distract ed by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using and content here.</p>
                        `,
    service_image: '/img/ppc.png',
    service_logo: [
      '/img/clients/nizam-uloom.png',
      '/img/clients/avagata.png',
      '/img/clients/accounting.png',
      '/img/clients/geek.png',
      '/img/clients/ezshoprz.png',
      '/img/clients/saree.png',
    ],
  },
  //     Social MediLink Marketing
  {
    slug: '  social-media-marketing',
    service_title: '  Social Media  Marketing',
    srevice_heading: 'The #1 Tool For Crerating Stunning Marketing',
    service_content: `<p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation.</p>
                        <p>It is a long established fact that a reader will be distract ed by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using and content here.</p>
                        `,
    service_image: '/img/smm.png',
    service_logo: [
      '/img/clients/nizam-uloom.png',
      '/img/clients/avagata.png',
      '/img/clients/accounting.png',
      '/img/clients/geek.png',
      '/img/clients/ezshoprz.png',
      '/img/clients/saree.png',
    ],
  },
  //E-mail Marketing
  {
    slug: 'e-mail-Marketing',
    service_title: ' E-Mail Marketing',
    srevice_heading: 'The #1 Tool For Crerating Stunning Marketing',
    service_content: `<p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation.</p>
                            <p>It is a long established fact that a reader will be distract ed by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using and content here.</p>
                            `,
    service_image: '/img/email-market.png',
    service_logo: [
      '/img/clients/nizam-uloom.png',
      '/img/clients/avagata.png',
      '/img/clients/accounting.png',
      '/img/clients/geek.png',
      '/img/clients/ezshoprz.png',
      '/img/clients/saree.png',
    ],
  },
]
