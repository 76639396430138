import React from 'react'
import Footer from '../../../containers/common/footer/Footer'
import Header from '../../../containers/common/header/Header'
import { BLOG } from '../../../shared/enums/blog_model'
import { Link } from 'react-router-dom'
import PageBanner from '../../../containers/common/pagebanner/PageBanner'

function BlogPage() {
  return (
    <div>
      <Header />
      <PageBanner heading={'Blog'} paragraph={'lorem Ipsum'} />
      <section className="blog">
        <div className="anim-elements">
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
        </div>
        <div className="container">
          <div className="row">
            {BLOG.map((item) => {
              return (
                <div className="col-sm-4 padding-15">
                  <div className="blog-item">
                    <div className="blog-thumb">
                      <img src={item.blog_image} alt="post" />
                      <span className="category">
                        <Link to={`/blog/${item.slug}`}>{item.blog_type}</Link>
                      </span>
                    </div>
                    <div className="blog-content">
                      <h3>
                        <Link to={`/blog/${item.slug}`}>
                          {item.blog_heading}
                        </Link>
                      </h3>
                      <p>{item.blog_description}</p>
                      <Link to={`/blog/${item.slug}`} className="read-more">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default React.memo(BlogPage)
