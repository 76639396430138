import React from 'react'

function PageBanner({ heading, paragraph }) {
  return (
    <div>
      <div className="header-height" style={{ height: 80 }} />

      <section className="page-header">
        <div class="anim-elements">
          <div class="anim-element"></div>
          <div class="anim-element"></div>
          <div class="anim-element"></div>
          <div class="anim-element"></div>
          <div class="anim-element"></div>
        </div>
        <div className="container">
          <div className="page-content text-center">
            <h2>{heading}</h2>
            <p>{paragraph}</p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default React.memo(PageBanner)
