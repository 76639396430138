import React from 'react'

function FunFact() {
  return (
    <div>
      <section className="counter-section pos-rel padding sm-pb-none">
        <div className="anim-elements">
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
        </div>
        <div className="container">
          <div className="section-heading mb-40 text-center">
            <h2>
              We're The Best Agency in NYC. Always <br />
              Deliver More Than Expected!
            </h2>
            <p>
              We provide marketing services to startups and small businesses to
              looking for a partner <br />
              of their digital media, design &amp; development, lead generation.
            </p>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 sm-padding">
              <div className="counter-content text-center">
                <h2 className="color-red">
                  <span
                    className="odometer odometer-auto-theme"
                    data-count={25}
                  >
                    <div className="odometer-inside">
                      <span className="odometer-digit">
                        <span className="odometer-digit-spacer">8</span>
                        <span className="odometer-digit-inner">
                          <span className="odometer-ribbon">
                            <span className="odometer-ribbon-inner">
                              <span className="odometer-value">5</span>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span className="odometer-digit">
                        <span className="odometer-digit-spacer">8</span>
                        <span className="odometer-digit-inner">
                          <span className="odometer-ribbon">
                            <span className="odometer-ribbon-inner">
                              <span className="odometer-value">5</span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </span>
                  k+
                </h2>
                <h3>Project Completed</h3>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 sm-padding">
              <div className="counter-content text-center">
                <h2 className="color-orange">
                  <span
                    className="odometer odometer-auto-theme"
                    data-count={385}
                  >
                    <div className="odometer-inside">
                      <span className="odometer-digit">
                        <span className="odometer-digit-spacer">8</span>
                        <span className="odometer-digit-inner">
                          <span className="odometer-ribbon">
                            <span className="odometer-ribbon-inner">
                              <span className="odometer-value">25</span>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span className="odometer-digit">
                        <span className="odometer-digit-spacer">8</span>
                        <span className="odometer-digit-inner">
                          <span className="odometer-ribbon">
                            <span className="odometer-ribbon-inner">
                              <span className="odometer-value">8</span>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span className="odometer-digit">
                        <span className="odometer-digit-spacer">8</span>
                        <span className="odometer-digit-inner">
                          <span className="odometer-ribbon">
                            <span className="odometer-ribbon-inner">
                              <span className="odometer-value">5</span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </span>
                  +
                </h2>
                <h3>Experts Suppor Team</h3>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 sm-padding">
              <div className="counter-content text-center">
                <h2 className="color-green">
                  <span
                    className="odometer odometer-auto-theme"
                    data-count={208}
                  >
                    <div className="odometer-inside">
                      <span className="odometer-digit">
                        <span className="odometer-digit-spacer">8</span>
                        <span className="odometer-digit-inner">
                          <span className="odometer-ribbon">
                            <span className="odometer-ribbon-inner">
                              <span className="odometer-value">2000+</span>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span className="odometer-digit">
                        <span className="odometer-digit-spacer">8</span>
                        <span className="odometer-digit-inner">
                          <span className="odometer-ribbon">
                            <span className="odometer-ribbon-inner">
                              <span className="odometer-value">0</span>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span className="odometer-digit">
                        <span className="odometer-digit-spacer">8</span>
                        <span className="odometer-digit-inner">
                          <span className="odometer-ribbon">
                            <span className="odometer-ribbon-inner">
                              <span className="odometer-value">8</span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </span>
                  +
                </h2>
                <h3>Our Worldwide Clients</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default React.memo(FunFact)
