import React, { useEffect } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './assets/css/fontawesome.min.css'
import './assets/css/themify-icons.css'
import './assets/css/elegant-line-icons.css'
import './assets/css/truno-icons.css'
import './assets/css/animate.min.css'
import './assets/css/bootstrap.min.css'
import './assets/css/slicknav.min.css'
import './assets/css/pricing-table.css'
import './assets/css/odometer.min.css'
import './assets/css/owl.carousel.css'
import './assets/css/venobox.min.css'
import './assets/css/main.css'
import './assets/css/custom.css'
import './assets/css/responsive.css'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store/store'

import { loadUser } from './store/actions/auth'
import Index from './containers/home/Index'
import About from './components/about/About'
import BlogPage from './components/pages/blog/BlogPage'
import ServiceComponent from './components/pages/services/ServiceComponent'
import Portfolio from './components/pages/portfolio/Portfolio'
import Contact from './components/pages/contact/Contact'
import SingleBlog from './components/pages/blog/SingleBlog'
import Privacy from './components/privacy-policy/Privacy'
import Terms from './components/terms-and-condition/Terms'

function App() {
  useEffect(() => {
    store.dispatch(loadUser())
  }, [])

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Index} />
          <Route exact path="/about-us" component={About} />
          <Route exact path="/blog" component={BlogPage} />
          <Route exact path="/blog/slug" component={BlogPage} />
          <Route exact path="/services/:slug" component={ServiceComponent} />
          <Route exact path="/our-portfolio" component={Portfolio} />
          <Route exact path="/contact-us" component={Contact} />
          <Route exact path="/privacy-policy" component={Privacy} />
          <Route exact path="/terms-and-conditions" component={Terms} />
          <Route exact path="/blog/:slug" component={SingleBlog} />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
