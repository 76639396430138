import React from 'react'
import Footer from '../../../containers/common/footer/Footer'
import Header from '../../../containers/common/header/Header'
import PageBanner from '../../../containers/common/pagebanner/PageBanner'

function Contact() {
  return (
    <div>
      <Header />
      <PageBanner heading={'Contact US'} />
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            width="100%"
            height={350}
            id="gmap_canvas"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3573.2527322813166!2d80.32415291484143!3d26.415319583344264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399c46f952e0a15f%3A0x4804872db324a027!2s128%2C%20Y%20Block%2C%20Kidwai%20Nagar%2C%20Kanpur%2C%20Uttar%20Pradesh%20208011!5e0!3m2!1sen!2sin!4v1640493687237!5m2!1sen!2sin"
            frameBorder={0}
            scrolling="no"
            marginHeight={0}
            marginWidth={0}
          />
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.mapouter{position:relative;text-align:right;height:350px;width:100%;}.gmap_canvas {overflow:hidden;background:none!important;height:350px;width:100%;}',
          }}
        />
      </div>
      <section className="contact-section padding">
        <div className="map" />
        <div className="container">
          <div className="contact-wrap d-flex align-items-center row">
            <div className="col-lg-6 sm-padding">
              <div className="contact-info">
                <h2>
                  Get in touch with us &amp; <br />
                  send us message today!
                </h2>
                <p>
                  Saasbiz is a different kind of architecture practice. Founded
                  by LoganCee in 1991, we’re an employee-owned firm pursuing a
                  democratic design process that values everyone’s input.
                </p>
                <h3>
                  128/619, Y, Block, Kidwai, Nagar <br />
                  kanpur Nagar, UP 208011
                </h3>
                <h4>
                  <span>Email:</span> webgrowsolutions@gmail.com
                  <br /> <span>Phone:</span> +91-8448062455
                </h4>
              </div>
            </div>
            <div className="col-lg-6 sm-padding">
              <div className="contact-form">
                <form
                  action="contact.php"
                  method="post"
                  id="ajax_form"
                  className="form-horizontal"
                >
                  <div className="form-group colum-row row">
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-12">
                      <textarea
                        id="message"
                        name="message"
                        cols={30}
                        rows={5}
                        className="form-control message"
                        placeholder="Message"
                        required
                        defaultValue={''}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-12">
                      <button
                        id="submit"
                        className="default-btn btn-blue"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                  <div id="form-messages" className="alert" role="alert" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  )
}

export default React.memo(Contact)
