import React from 'react'
import { Link } from 'react-router-dom'

function LatestCode() {
  return (
    <div>
      <section className="content-section padding">
        <div className="anim-elements">
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 xs-padding">
              <div className="content-info">
                <img src="img/content-1.png" alt="img" />
              </div>
            </div>
            <div className="col-md-6 xs-padding">
              <div className="content-info">
                <h2>
                  Design And Build By The <br />
                  Latest Code Integration.
                </h2>
                <p>
                  We provide marketing services to startups and small businesses
                  to looking for a partner of their digital media, design &amp;
                  development, lead generation and communications requirents.
                </p>
                <Link className="default-btn" to="/contact-us">
                  Get Started
                  <span />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default LatestCode
