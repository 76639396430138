import React from 'react'
import Slider from 'react-slick'

const testimoials = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  centerMargin: '50px',
  infinite: true,
  speed: 100,
  className: 'mar',

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}
function Testimonial() {
  return (
    <div>
      <section id="reviews" className="testimonial-section pos-rel padding">
        <div className="overlay-shape-left" />
        <div className="overlay-shape-right" />
        <div className="anim-elements">
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
        </div>
        <div className="container">
          <div className="section-heading mb-40 text-center">
            <h2>Clients' Words of Appreciation</h2>
            <p>THEY SAY WE DID GREAT JOB!</p>

            <div className="mt-40">
              <Slider {...testimoials}>
                <div className="testimonial-item">
                  <div className="author-details">
                    <div className="author-info">
                      <h3>
                        Vijay Sharma<span>New Delhi</span>
                      </h3>
                    </div>
                    <ul className="rating">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                  <p>
                    It takes a lot of patience and hard work to bring someone's
                    idea to reality. I would love to compliment the Webgrow
                    Solutions team for being so creative and probative that they
                    could bring our imagination look real and also for being
                    suggestive about making it much better that we had allowed.
                  </p>
                </div>

                <div className="testimonial-item">
                  <div className="author-details">
                    <div className="author-info">
                      <h3>
                        Mohit Raina<span>Lucknow (UP)</span>
                      </h3>
                    </div>
                    <ul className="rating">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                  <p>
                    Webgrow Solutions is handling our website from last 3 months
                    and we're completely satisfied with the issues we're
                    getting. It's one of the stylish digital marketing companies
                    grounded Kanpur.
                  </p>
                </div>

                <div className="testimonial-item">
                  <div className="author-details">
                    <div className="author-info">
                      <h3>
                        Rahul Pandey<span> Agra (UP)</span>
                      </h3>
                    </div>
                    <ul className="rating">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                  <p>
                    Working with Webgrow Solutions was a great experience. They
                    create my website as per I need. They suggest me the ways i
                    can grow my business with marketing. I am fully satisfied
                    with their services.
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default React.memo(Testimonial)
