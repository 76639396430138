import React from 'react'
import Slider from 'react-slick'
function Technology() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    speed: 100,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  }
  return (
    <div>
      <div className="sponsor-section padding">
        <div className="anim-elements">
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
        </div>
        <div className="container">
          <div className="col-md-12">
            <Slider {...settings}>
              <div className="owl-item active">
                <div className="sponsor-item">
                  <img src="img/wordpress.png" alt="sponsor" />
                </div>
              </div>

              <div className="owl-item active">
                <div className="sponsor-item">
                  <img src="img/firebase.png" alt="sponsor" />
                </div>
              </div>
              <div className="owl-item">
                <div className="sponsor-item">
                  <img src="img/aws.png" alt="sponsor" />
                </div>
              </div>
              <div>
                <div className="sponsor-item">
                  <img src="img/css-3.png" alt="sponsor" />
                </div>
              </div>
              <div>
                <div className="sponsor-item">
                  <img src="img/HTML5_Logo_512.png" alt="sponsor" />
                </div>
              </div>
              <div>
                <div className="sponsor-item">
                  <img src="img/jquery-icon1.png" alt="sponsor" />
                </div>
              </div>

              <div>
                <div className="sponsor-item">
                  <img src="img/php.png" alt="sponsor" />
                </div>
              </div>
              <div className="owl-item cloned">
                <div className="sponsor-item">
                  <img src="img/node.png" alt="sponsor" />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Technology)
