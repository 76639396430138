import React, { useEffect, useState } from 'react'
import renderHTML from 'react-render-html'
import Footer from '../../../containers/common/footer/Footer'
import Header from '../../../containers/common/header/Header'
import PageBanner from '../../../containers/common/pagebanner/PageBanner'
import { BLOG } from '../../../shared/enums/blog_model'

function SingleBlog({ match }) {
  const [blog, setblog] = useState(null)
  useEffect(() => {
    if (match.params.slug) {
      const filterData = BLOG.filter((items) => {
        return items.slug === match.params.slug
      })
      if (filterData && filterData.length > 0) {
        setblog(filterData[0])
      }
    }
  }, [match.params.slug])
  return (
    <div>
      <Header />
      {blog && <PageBanner heading={blog.blog_heading} />}
      {blog && (
        <section className="blog-section padding">
          <div className="container">
            <div className="blog-wrap row">
              <div className="col-lg-12 padding-15">
                <div className="blog-single-wrap">
                  <div className="blog-thumb">
                    <img src={blog.blog_image} alt="img" />
                  </div>
                  <div className="blog-single-content">
                    <h2>
                      <a href="#">
                        Minimalist trending in modern architecture 2019
                      </a>
                    </h2>
                    <ul className="single-post-meta">
                      <li>
                        <i className="fa fa-user" /> <a href="#">Admin</a>
                      </li>
                      <li>
                        <i className="fa fa-calendar" />{' '}
                        <a href="#">19 Feb, 2019</a>
                      </li>
                    </ul>
                    <div>
                      {blog.blog_description &&
                        renderHTML(blog.blog_description)}
                    </div>
                  </div>
                </div>
                {/*/.blog-single*/}
              </div>
              {/*/.col-lg-8*/}
            </div>
            {/*/.blog-wrap*/}
          </div>
        </section>
      )}
      <Footer />
      {/*/.blog-section*/}
    </div>
  )
}

export default SingleBlog
