import React from 'react'

function FormComponent() {
  return (
    <div>
      <form
        action="contact.php"
        method="post"
        id="ajax_form"
        className="form-horizontal"
      >
        <div className="form-group colum-row row">
          <div className="col-sm-6">
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              placeholder="Name"
              required
            />
          </div>
          <div className="col-sm-6">
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              placeholder="Email"
              required
              autoComplete="off"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-12">
            <textarea
              id="message"
              name="message"
              cols={30}
              rows={5}
              className="form-control message"
              placeholder="Message"
              required
              defaultValue={''}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-12">
            <button id="submit" className="default-btn btn-blue" type="submit">
              Send Message
            </button>
          </div>
        </div>
        <div id="form-messages" className="alert" role="alert" />
      </form>
    </div>
  )
}

export default FormComponent
