import React from 'react'
import FormComponent from '../../components/FormComponent'

function ContactUsToday() {
  return (
    <div>
      <section className="download-section pos-rel padding">
        <div className="anim-elements">
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-8 xs-padding">
              <div className="contact-form">
                <h2 style={{ paddingBottom: 10 }}>Send us message today!</h2>
                <FormComponent />
              </div>
            </div>
            <div className="col-lg-6 col-md-4 xs-padding">
              <img src="/img/content-2.png" alt="img" />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default React.memo(ContactUsToday)
