import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { SERVICES } from '../../../shared/enums/services_model'

function Header() {
  const [open, setopen] = useState(false)
  const [subMenu, setsubMenu] = useState(false)
  return (
    <header id="header" className="header-section">
      <div className="container">
        <nav className="navbar">
          <div className="slicknav_menu">
            <a
              href="#"
              aria-haspopup="true"
              role="button"
              tabIndex={0}
              className="slicknav_btn slicknav_open"
              style={{ outline: 'none' }}
              onClick={() => {
                setopen(!open)
              }}
            >
              <span className="slicknav_menutxt" />
              <span className="slicknav_icon slicknav_no-text">
                <span className="slicknav_icon-bar" />
                <span className="slicknav_icon-bar" />
                <span className="slicknav_icon-bar" />
              </span>
            </a>
            <ul
              className="slicknav_nav"
              aria-hidden="false"
              role="menu"
              style={{ display: open ? 'block' : 'none' }}
            >
              <li className="slicknav_collapsed slicknav_parent">
                <a
                  href="#"
                  role="menuitem"
                  aria-haspopup="true"
                  tabIndex={0}
                  className="slicknav_item slicknav_row"
                  style={{ outline: 'none' }}
                ></a>
                <Link className="nav-link active" to="/">
                  Home<span className="sr-only">(current)</span>
                </Link>
              </li>
              <li>
                <Link data-scroll className="nav-link" to="/about-us">
                  About Us
                </Link>
              </li>
              <li
                className="slicknav_collapsed slicknav_parent"
                onClick={() => {
                  setsubMenu(!subMenu)
                }}
              >
                <a href="#" tabIndex={0}>
                  Our Services
                  <span className="slicknav_arrow">►</span>
                </a>

                <ul
                  role="menu"
                  className="slicknav_hidden"
                  aria-hidden="true"
                  style={{ display: subMenu ? 'block' : 'none' }}
                >
                  {SERVICES &&
                    SERVICES.map((item) => {
                      return (
                        <li>
                          <Link to={`/services/${item.slug}`}>
                            {item.service_title}
                          </Link>
                        </li>
                      )
                    })}
                </ul>
              </li>
              <li>
                <Link className="nav-link" to="/blog">
                  Blog
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="/our-portfolio">
                  Our Portfolio
                </Link>
              </li>
              <li>
                <Link to="/contact-us">Contact</Link>
              </li>
            </ul>
          </div>

          <Link to="/" className="navbar-brand">
            <img src="/img/logo-dark.png" alt="" />
          </Link>

          <div className="d-flex menu-wrap">
            <div id="mainmenu" className="mainmenu">
              <ul className="nav">
                <li>
                  <Link className="nav-link active" to="/">
                    Home<span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li>
                  <Link data-scroll className="nav-link" to="/about-us">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="#">Our Services</Link>
                  <ul>
                    {SERVICES &&
                      SERVICES.map((item) => {
                        return (
                          <li>
                            <Link to={`/services/${item.slug}`}>
                              {item.service_title}
                            </Link>
                          </li>
                        )
                      })}
                  </ul>
                </li>
                <li>
                  <Link className="nav-link" to="/blog">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/our-portfolio">
                    Our Portfolio
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact</Link>
                </li>
              </ul>
            </div>
            <div className="header-right">
              <Link className="menu-btn anim-btn" to="/contact-us">
                Get Started
                <span />
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default React.memo(Header)
