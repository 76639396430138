import React from 'react'
import Footer from '../../../containers/common/footer/Footer'
import Header from '../../../containers/common/header/Header'
import PageBanner from '../../../containers/common/pagebanner/PageBanner'
import { PORTFOLIO } from '../../../shared/enums/portfolio_model'
import { Link } from 'react-router-dom'

function Portfolio() {
  return (
    <div>
      <Header />
      <PageBanner heading={'Our Portfolio'} />
      <h1>Coming Soon</h1>
      {/* <section className="padding">
        <div className="container">
          <div className="row">
            {PORTFOLIO.map((item) => {
              return (
                <div className="col-md-4">
                  <div className="isotope_item up-hor">
                    <div className="item-image">
                      <img
                        src={item.item_image}
                        alt="image"
                        className="img-fluid"
                      />
                    </div>
                    <div className="item-info-div shdo">
                      <h4>{item.portfolio_sitename}</h4>
                      <p> {item.portfolio_type}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  )
}

export default React.memo(Portfolio)
