export const BLOG = [
  {
    slug: 'choosing-the-right-website-designing-company-in-india',
    blog_image: '/img/webdesign1.jpg',
    blog_type: 'Website Designing',
    blog_heading: 'Choosing The Right Website Designing Company in India',
    blog_description:
      '<p>Moment, retaining a website is as significant as having a business card. It s the most important marketing tool for connecting druggies with brands and vice versa. Still, its the most prominent tool for erecting a brand image for your company through the internet. It not only enhances your company to boost deals, but its also helpful in allowing you to make your brand in the assiduity. Whether you have just started your business or allowing about starting one, an engaging website will support your business to new heights snappily. Launching a website is one of the topmost challenges for numerous businesses, as the website is the primary print that your guests will have. So, its largely important to pick the stylish website designing company in India that understands your thing and delivers the stylish results.</p>',
  },
  {
    slug: 'hiring-a-freelancer-vs-a-company-for-your-web-design-needs',
    blog_image: '/img/post-1.jpg',
    blog_type: 'Freelancer Vs a Company',
    blog_heading: 'Hiring a Freelancer Vs a Company for your Web Design needs',
    blog_description:
      '<p>We can understand that it can be challenging for a business owner to decide whether to hire a web designing company or a freelancer. This article will help you make a decision. So let us understand which one is best for you.</p> <strong>Responsive web design is very important for any estate agent that is serious about providing the best for their audience.</strong><div className = "blog-lines"><ul><li><strong>1) Engage an extensive audience: </strong>Your audience will access your website from a wide range of different devices in all shapes and sizes. Responsive design will fit in with them no matter how big or small their screen is.</li><li><strong>1) Engage an extensive audience: </strong>Your audience will access your website from a wide range of different devices in all shapes and sizes. Responsive design will fit in with them no matter how big or small their screen is.</li></ul></div>',
  },
  {
    slug: 'app-development',
    blog_image: '/img/post-1.jpg',
    blog_type: 'App',
    blog_heading: 'Minimalist trending in modern architecture 2019',
    blog_description:
      'Building first evolved out dynamics between needs means available building materials attendant skills.',
  },
]
