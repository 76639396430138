import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div>
      <footer className="widget-section widget-3">
        <div className="anim-elements">
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
        </div>
        <div className="container">
          <div className="widget-wrap row padding">
            <div className="col-md-3 col-sm-6 xs-padding">
              <div className="widget-content">
                <h3>Home Links</h3>
                <ul className="widget-list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/our-portfolio">Our Portfolio</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 xs-padding">
              <div className="widget-content">
                <h3>Our Services</h3>
                <ul className="widget-list">
                  <li>
                    <Link to="/services/wordpress-development">
                      Wordpress Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/software-development">
                      Software Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/app-development">App Development</Link>
                  </li>
                  <li>
                    <Link to="/services/web-development">Web Development</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 xs-padding">
              <div className="widget-content">
                <h3>Our Services</h3>
                <ul className="widget-list">
                  <li>
                    <Link to="/services/php-development">PHP Development</Link>
                  </li>
                  <li>
                    <Link to="/services/ecommerce-development">
                      Ecommerce Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/digital-marketing">
                      Digital Marketing
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/search-engine-optimization">
                      {' '}
                      Search Engine Optimization
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 xs-padding">
              <div className="widget-content">
                <h3>Contact Us</h3>
                <ul className="widget-list">
                  <li>
                    <Link to="/terms-and-conditions">Terms of services</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-section footer-2">
          <div className="container">
            <div className="footer-wrap row">
              <div className="col-md-4">
                <div className="footer-content">
                  <p>
                    Copyright © 2022 Webgrow Solutions. All rights reserved.
                  </p>
                </div>
              </div>
              <div className="col-md-4 xs-padding">
                <div className="footer-item">
                  <img src="img/logo-dark.png" alt="img" />
                </div>
              </div>
              <div className="col-md-4">
                <ul className="footer-social">
                  <li>
                    <Link to="https://www.facebook.com/profile.php?id=100078294966751">
                      <i className="fab fa-facebook" />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.instagram.com/webgrowsolutions.official/">
                      <i className="fab fa-instagram" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default React.memo(Footer)
