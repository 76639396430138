import React, { useState, useEffect } from 'react'
import renderHTML from 'react-render-html'
import { Link } from 'react-router-dom'
import Footer from '../common/footer/Footer'
import { BLOG } from '../../shared/enums/blog_model'

function Blog() {
  return (
    <div>
      <section className="blog">
        <div className="anim-elements">
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
          <div className="anim-element" />
        </div>
        <div className="container">
          <div className="section-heading text-center mb-40">
            <h2>Our Blog</h2>
          </div>
          <div className="row">
            {BLOG.map((item) => {
              return (
                <div className="col-sm-4 padding-15">
                  <div className="blog-item">
                    <div className="blog-thumb">
                      <img src={item.blog_image} alt="post" />
                      <span className="category">
                        <Link to={`/blog/${item.slug}`}>{item.blog_type}</Link>
                      </span>
                    </div>
                    <div className="blog-content">
                      <h3>
                        <Link to={`/blog/${item.slug}`}>
                          {item.blog_heading}
                        </Link>
                      </h3>
                      <p>
                        {item.blog_description &&
                          renderHTML(item.blog_description.substr(0, 200))}
                      </p>
                      <Link to={`/blog/${item.slug}`} className="read-more">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </div>
  )
}

export default React.memo(Blog)
