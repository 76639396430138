import React from 'react'
import { Link } from 'react-router-dom'
import graphicDesign from '../../assets/img/graphic-design.png'
import About from '../../components/about/About'
import AboutHome from '../about/AboutHome'
import Banner from '../banner/Banner'
import Blog from '../blog/Blog'
import Footer from '../common/footer/Footer'
import Header from '../common/header/Header'
import ContactUsToday from '../contactus/ContactUsToday'
import Faq from '../faq/Faq'
import Features from '../Feature/Features'
import FunFact from '../funfact/FunFact'
import LatestCode from '../latest/LatestCode'
import Technology from '../technology/Technology'
import Testimonial from '../testimonial/Testimonial'

function Index() {
  return (
    <div>
      <Header />

      <Banner />

      <Features />

      <AboutHome />
      <FunFact />

      <LatestCode />

      <Technology />

      <Testimonial />

      <ContactUsToday />
      <Blog />

      <Faq />

      <Footer />
    </div>
  )
}

export default React.memo(Index)
