import React, { useEffect, useState } from 'react'
import renderHTML from 'react-render-html'
import Header from '../../../containers/common/header/Header'
import PageBanner from '../../../containers/common/pagebanner/PageBanner'
import Slider from 'react-slick'
import Testimonial from '../../../containers/testimonial/Testimonial'
import Footer from '../../../containers/common/footer/Footer'
import ContactUsToday from '../../../containers/contactus/ContactUsToday'
import { SERVICES } from '../../../shared/enums/services_model'

function ServiceComponent({ match }) {
  const [service, setService] = useState(null)
  const wordpress = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    speed: 100,
  }
  useEffect(() => {
    if (match.params.slug) {
      const filterData = SERVICES.filter((items) => {
        return items.slug === match.params.slug
      })
      if (filterData && filterData.length > 0) {
        setService(filterData[0])
      }
    }
  }, [match.params.slug])
  return (
    <div>
      <Header />
      {service && (
        <>
          <PageBanner heading={service.service_title} />
          <section className="content-section content-3 pos-rel padding sm-pb-none">
            <div className="ov-shape-4 d-none d-lg-block" />
            <div className="container">
              <div className="content-wrap row align-items-center">
                <div className="col-lg-6 sm-padding">
                  <div className="content-info">
                    <h2>{service.srevice_heading}</h2>
                    <div>
                      {service.service_content &&
                        renderHTML(service.service_content)}
                    </div>
                    <a className="default-btn" href="#">
                      Get A Free Quote
                      <span />
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 sm-padding">
                  <img src={service.service_image} />
                </div>
              </div>
            </div>
          </section>
          <div className="sponsor-section bg-grey padding">
            <div className="container">
              <Slider {...wordpress}>
                {service.service_logo &&
                  service.service_logo.map((item) => {
                    return (
                      <div className="sponsor-item">
                        <img src={item} />
                      </div>
                    )
                  })}
              </Slider>
            </div>
          </div>
        </>
      )}

      <Testimonial />
      <ContactUsToday />
      <Footer />
    </div>
  )
}

export default React.memo(ServiceComponent)
